import React from "react"
import { useState } from "react"
import Modal from "react-modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons"

Modal.setAppElement("#___gatsby")

const CatalogFrame = props => {
  const { src, title } = props
  return (
    <>
      <iframe
        title={title}
        src={src}
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        scrolling="auto"
        style={{
          display: "block",
          width: "100%",
          height: "calc(100% - 50px)",
          border: "none",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      ></iframe>
    </>
  )
}

const CatalogModal = props => {
  const { title, img, src } = props
  const [isOpen, setIsOpen] = useState(false)

  const toggleModal = e => {
    setIsOpen(!isOpen)
  }

  return (
    <span className="catalogModalWrapper">
      <button onClick={toggleModal} onKeyUp={toggleModal}>
        <img src={img} alt={`${title} catalog`} />
        <p>{title}</p>
      </button>

      {isOpen ? (
        <Modal
          id="catalogModal"
          isOpen={isOpen}
          contentLabel={title}
          shouldCloseOnOverlayClick={true}
          onRequestClose={toggleModal}
          className="catalogModal"
          overlayClassName="overlay"
          closeTimeoutMS={250}
        >
          <h4 className="center">{title}</h4>
          <FontAwesomeIcon
            onClick={toggleModal}
            icon={faTimesCircle}
            alt="Close"
            className="modalCloseButton"
          />
          <br />
          <CatalogFrame src={src} title={title} />
        </Modal>
      ) : null}
    </span>
  )
}

export default CatalogModal

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../components/seo"
import CatalogModal from "../components/catalogModal"

import "../styles/catalogs.css"
import "../styles/catalogModal.css"

const Catalogs = props => {
  const { allDirectusCatalog } = useStaticQuery(
    graphql`
      {
        allDirectusCatalog {
          nodes {
            url
            title
            standard
            id
            cover {
              title
              localFile {
                publicURL
              }
            }
          }
        }
      }
    `
  )

  return (
    <>
      <SEO title="Catalogs" />
      <div className="content bg-white catalogWrapper">
        <div className="catalogList">
          <div>
            <h4>Apparel For Any Occassion!</h4>
            <p>
              At Phoenix Screenprinting, we offer a wide selection of apparel
              and accessories for your screen printing and embroidery needs.
              Click on the cover images below to browse through catalogs for
              three of the largest distributors in the industry.
            </p>
          </div>

          {allDirectusCatalog.nodes.map((node, index) => {
            const { standard, title, cover, url } = node
            if (standard) {
              return (
                <div key={index}>
                  <CatalogModal
                    title={title}
                    img={cover.localFile.publicURL}
                    src={url}
                  />
                </div>
              )
            } else {
              return null
            }
          })}
        </div>
      </div>

      <div className="content bg-black catalogWrapper">
        <div className="catalogList">
          <div>
            <h4>Specialized Catalogs</h4>
            <p>
              Looking for something else? These catalogs offer apparel for more
              specific purposes.
            </p>
          </div>

          {allDirectusCatalog.nodes.map((node, index) => {
            const { standard, title, cover, url } = node
            if (!standard) {
              return (
                <div key={index}>
                  <CatalogModal
                    title={title}
                    img={cover.localFile.publicURL}
                    src={url}
                  />
                </div>
              )
            } else {
              return null
            }
          })}
        </div>
      </div>

      <div className="content bg-white ">
        <div className="catalogList">
          <div>
            <h4>Can't Find What You're Looking For?</h4>
            <p>
              If there is a style or brand that you cannot find in the above
              catalogs, call the office or stop by our showroom and a customer
              service representative will help you find what you need.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Catalogs
